@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap");
:root {
  --sidebar-flex: 1.5;
  --condiv-flex: 6;
  --fontsize-p: 1.2rem;
  --fontsize-nav: 1.6rem;
  --typing-font: 3rem;
}
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Raleway";
}
.App {
  display: flex;
  width: 100vw;
  min-height: 100vh;
}
nav {
  padding: 60px 30px 0px 30px;
  flex: var(--sidebar-flex);
  /*background: rgb(238,103,143);
  background: linear-gradient(180deg, rgba(238,103,143,1) 50%, rgba(132,124,252,1) 80%);*/
  background: rgb(34, 193, 195);
  background: -moz-linear-gradient(
    0deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(45, 63, 253, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(45, 63, 253, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(45, 63, 253, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#22c1c3",endColorstr="#2d3ffd",GradientType=1);
}
.condiv {
  padding: 100px;
  background-color: #f4f5f9;
  flex: var(--condiv-flex);
}

.home {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.profilepic {
  border-radius: 50%;
  width: 200px;
  margin: 30px;
}
.typingeffect {
  font-size: var(--typing-font);
  margin-bottom: 30px;
}

nav ul {
  font-size: var(--fontsize-nav);
  text-align: center;
  list-style-type: none;
  text-decoration: none;
  color: white !important;
}
nav ul li {
  margin: 40px;
  padding: 10px 10px;
}

a {
  text-decoration: none;
  color: white !important;
}

a:hover {
  text-decoration: underline;
}

.subtopic {
  margin: 10px 10px 30px 10px !important;
}
p {
  font-size: var(--fontsize-p);
}
h1,
h2,
h3,
h4,
p {
  margin: 10px;
}
.social {
  /*position: absolute;*/
  /*margin-top: 20px;*/
  margin: 20px;
  padding-left: 30px;;
  display: flex;
  bottom: 60px;
}
.social i {
  padding-right: 30px;
  font-size: 25px;
  color: #9a9b9c;
}

.social i:hover {
  color: #000000;
}

.widecard {
  border: 0px solid #9a9b9c;
  display: flex;
  margin: 30px 0px 30px 0px;
}
.widecard:hover {
  color: #1b1d20;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.active {
  font-weight: bolder;
}
.skills ul li {
  list-style-type: disc;
  margin: 30px;
}

@media all and (max-width: 450px) {
  :root {
    --fontsize-nav: 1rem;
    --sidebar-flex: 0;
    --typing-font: 2rem;
  }
  .App {
    flex-direction: column;
  }
  .sidebar {
    padding: 30px;
    background: rgb(238, 103, 143);
    background: linear-gradient(
      90deg,
      rgba(238, 103, 143, 1) 50%,
      rgba(132, 124, 252, 1) 80%
    );
  }
  .condiv {
    padding: 50px;
  }
  nav ul {
    display: flex;
  }
  nav ul li {
    margin: 10px;
  }
  .social {
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    margin-top: 100px;
    align-items: center;
  }
  .social i {
    padding-right: 0px;
  }
}

.social .fa-facebook,
.social .fa-facebook-square {
  color: #3b5998;
}

.social .fa-twitter,
.social .fa-twitter-square {
  color: #00aced;
}

.social .fa-x-twitter{
  color: #00aced;
}

.social .fa-linkedin,
.social .fa-linkedin-square {
  color: #007bb6;
}

.social .fa-instagram {
  color: #c13584;
}

/* Input controls */
input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  resize: vertical;
}

input[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}
